/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		//inicio tab-module
		//tabModule.init();
		// inicio bootstrap lightbox
		$(document).on('click', '[data-toggle="lightbox"]', function(event){
			event.preventDefault();
			$(this).ekkoLightbox({
				alwaysShowClose: true,
				showArrows: true,
			});
		});
		
		//inicio sliphover
		$('#container').sliphover({
      backgroundColorAttr: 'data-background'
    });
		$('#container2').sliphover({
      backgroundColorAttr: 'data-background'
    });
		//fim sliphover
        
    // slick (slide)
    $('#location-slide.tae-lista-produtos').slick({
      dots: false,
      infinite: false,
      speed: 300,
      rows: 1,
      slidesToShow: 4.2,
      centerMode: false,
      variableWidth: false,
      cssEase: 'linear',
      slidesToScroll: 1,
      swipeToSlide: false,
      prevArrow: '.tae-produtos-destaque #slideLeftValue',
      nextArrow: '.tae-produtos-destaque #slideRightValue',
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3.7,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
          }
        }
      ]
    });
        
    $('#location-slide.tae-lista-projetos').slick({
      dots: false,
      infinite: false,
      speed: 300,
      rows: 1,
      slidesToShow: 4.3,
      centerMode: false,
      variableWidth: false,
      prevArrow: '.tae-projetos-especiais #slideLeftValue',
      nextArrow: '.tae-projetos-especiais #slideRightValue',
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3.7,
            slidesToScroll: 1.7,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 1.3,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 1.3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 1.3
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1.2
          }
        }
      ]
    });
    
    // desabilita slick na pagina archive caixa dagua
    $('.post-type-archive-caixa-dagua #location-slide.tae-lista-produtos').unslick();
    $('.post-type-archive-tanque #location-slide.tae-lista-produtos').unslick();
    $('.page.caixas-dagua-e-tanques-de-fibra #location-slide.tae-lista-produtos').unslick();
    
    // fim slick
        
		
		//inicio btn-toggle
		$(".btn-toggle").click(function(e){
			e.preventDefault();
			el = $(this).data('element');
			el2 = $(this).data('element2');
			$(el).fadeIn(1000);
			$(el2).fadeOut(300);
			$('html, body').animate({scrollTop : $("#mt-embalagem-img").offset().top},800);
		});
		//fim btn-toggle
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
